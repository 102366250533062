$appMargin: 3em auto 0px auto;
$primary: #2e70b4;
$accent1: #f47b3d;

.hidden {
  display: none;
}

.fluid {
  max-width: 100%;
}

.round {
  border-radius: 50%;
}

body {
  font-size: 14px;
  font-family: "Microsoft YaHei", "微软雅黑", Roboto, "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", Arial, sans-serif, "Noto Sans CJK SC";
  color: #333333;
}

h1, h2, h3 {
  letter-spacing: 0;
  font-weight: initial;
}

.flexSpacer {
  flex-grow: 1;
}

div.content {
	display: flex;
	max-width: 1200px;
  margin: 0px auto;
}

ul {
  list-style-type: none;
  padding: 0px;
  margin: 0px;
}

picture {
  display: block;
}

.primary {
  color: $primary;
}

.accent1 {
  color: $accent1;
}

@keyframes vafadeIn {
  from {opacity: 0;}
  to {opacity: 1;}
}

@keyframes bounceG{
  0% {
      transform:scale(1.2);
      opacity:1
  }

  100% {
      transform:scale(0.7);
      opacity:0.1
  }
}


// 顶端封面图
header.cover {
  display: flex;
  position: relative;
  align-items: center;
  picture {
    height: 197px;
    width: 100%;
    overflow: hidden;
    text-align: center;
  }
  div.content {
    text-align: center;
    width: 100%;
    position: absolute;
    transform: translateX(-50%);
    left: 50%;
    top: 50%;
  }
  p {
    font-size: 24px;
    color: white;
    width: 100%;
    text-align: center;
    margin: 0px;
  }
}


// 新闻中心
section.news {
  margin: $appMargin;

  main {
    div.content {
      display: block;
    }

    article {
      padding: 2.5em 0px;
      border-bottom: 1px solid #e8e8e8;
      overflow: hidden;
    }

    header {
      width: 14.25em;
      float: left;
    }

    picture {
      max-width: 200px;
    }

    h1 {
      font-size: 18px;
      margin: 0px auto 1em auto;
    }
  }

  > nav.tab {
    a {
      color: #333333;
    }
    li.active, li:hover {
      background-color: $primary;
      a {
        color: #fff;
      }
    }
  }

  > main { 
    margin: 2em auto;
    min-height: 50vh;
  }

  div.abbr {
    height: 4em;
  }

  nav.tab {
    background-color: #f7f9fa;
    border-bottom: 1px solid #e8e8e8;
    ul {
      margin-bottom: 0;
    }
    li {
      transition: all 0.3s cubic-bezier(0.19, 1, 0.22, 1);
      display: inline-block;
      padding: 1.3em 1.8em;
      font-size: 16px;
      cursor: pointer;
      border-bottom: 3px solid transparent;
      box-shadow: 0 0 0 1px #eeeeee;
      margin-left: -3px;
    }
  }

  nav.pagination {
    border: unset;
    text-align: center;
    position: relative;
    ul {
      display: block;
    }
    li {
      display: inline-block;
      width: 25px;
      height: 25px;
      line-height: 25px;
      border-radius: 50%;
    }
    li.active {
      background-color: $primary;
    }
    li.active a{
      color: #fff;
    }
    a:hover {
      color: #2e70b4!important;
      text-decoration: none;
      box-shadow: none;
    }
    .hold {
      position: absolute;
      right: 5em;
    }
    .page-item {
      margin-right: 1em;
      font-size: 1em;
      font-weight: 400;
    }
  }

  article main {
    color: #999999;
    margin-left: 2em;
    float: left;
    .from {
      margin-right: 3em;
    }
    .link-more {
      display: none;
    }
  }

}

// 新闻单页
section.single {
  margin: $appMargin;
  #primary {
    min-height: 80vh;
  }
  #main {
    margin: 10em auto 2em;
  }
  div.content {
    display: block;
    padding: 1em 2em;
  }
  .entry-header div.content {
    border-bottom: 1px solid rgba(204, 204, 204, 1);
  }
  .entry-meta {
    font-size: 14px;
    text-align: center;
    margin: 1.5em auto;
  }
  .entry-title {
    font-size: 36px;
    text-align: center;
    color: $primary;
  }
  .entry-content {
    color: black;
    font-size: 18px;
    line-height: 2em;
  }
  .from {
    margin-right: 2em;
  }
}

// 政务服务
section.govService {
  margin: $appMargin;
  > nav {
    a {
      color: #333333;
    }
    li.active, li:hover {
      background-color: $primary;
      a {
        color: white;
      }
    }
  }
  > main {
    padding-bottom: 5em;
    min-height: 50vh;
    h1{ height: 85px;}
    h1.dwmy {
      background: url("../../images/serve/dwmy.png");
    }
    
    h1.jjhz {
      background: url("../../images/serve/jjhz.png");
    }
    
    h1.qyyhfc {
      background: url("../../images/serve/qyyhfc.png");
    }

    h1.wmyw {
      background: url("../../images/serve/wmyw.png");
    }

    h1.zxcx {
      background: url("../../images/serve/zxcx.png");
    }

    h1.zxbl {
      background: url("../../images/serve/zxbl.png");
    }

    h1.ywzx {
      background: url("../../images/serve/ywzx.png");
    }

    h1.sscx {
      background: url("../../images/serve/sscx.png");
    }

    h1.bszn {
      background: url("../../images/serve/bszn.png");
    }
    
    h1.bsxt {
      background: url("../../images/serve/bsxt.png");
    }

    h1.ggcx {
      background: url("../../images/serve/ggcx.png");
    }
    article {
      text-align: center;
      margin: 1em;
      border: 1px solid #f3f3f3;
      border-radius: 30px;
      height: 5em;
      width: 19em;
      // display: flex;
      align-items: center;
      justify-content: center;
      padding: 1em;
      transition: all 0.3s cubic-bezier(0.19, 1, 0.22, 1);
      box-shadow: 0 0 1px rgba(0, 0, 0, 0.3);
    }
    article:hover {
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
      border: 1px solid #2e70b4;
      background: #2e70b4;
      color: #fff;
    }
  }
  nav.tab {
    background-color: #f7f9fa;
    border-bottom: 1px solid #e8e8e8;
    ul {
      margin-bottom: 0;
    }
    li {
      transition: all 0.3s cubic-bezier(0.19, 1, 0.22, 1);
      display: inline-block;
      width: 199px;
      padding: 1.3em 1.8em;
      font-size: 16px;
      cursor: pointer;
      box-shadow: 0 0 0 1px #eeeeee;
      margin-left: -3px;
      text-align: center;
    }
  }
  div.content {
    display: block;
  }
  div.items {
    // display: flex;
    // flex-wrap: wrap;
    display: inline-block;
    a {
      display: block;
      float: left;
    }
  }
  div.secHold {
    padding: 3em 0;
    animation: vafadeIn 1s;
  }
}

// 关于我们
section.about {
  margin: $appMargin;
  div.content {
    display: block;
    max-width: 1000px;
  }
  div.row {
    display: flex;
  }
  h1 {
    text-align: center;
    font-size: 24px;
  }
  > main {
    picture {
      text-align: center;
      margin: 3em auto;
    }
    p {
      line-height: 2em;
    }
    section {
      margin-top: 5em;
    } 
  }

  section.function {
    h2 {
      position: relative;
      padding-left: 3em;
      font-weight: 400;
      font-size: 20px;
      img {
        position: absolute;
        left: 0px;
        top: -0.5em;
      }
    }
    p {
      padding-left: 4em;
      font-size: 16px;
    }
    picture {
      margin-bottom: 6em;
    }
  }
  
  > footer {
    background-color: #f7f9fa;
    padding: 3em 0px;
    .logo {
      margin-right: 1em;
    }
  }
}

// 入驻指引
section.guidance {
  margin: $appMargin;
  h1 {
    text-align: center;
    font-size: 24px;
    font-weight: 400;
    margin: 2em auto;
  }
  h2 {
    font-size: 20px;
    font-weight: 400;
    padding: 0px;
  }
  h3 {
    font-size: 16px;
    font-weight: 400;
  }
  div.content {
    padding: 0px 6em;
  }

  > nav {
    a {
      color: #333333;
    }
    li.active, li:hover {
      background-color: $primary;
      a {
        color: #fff;
      }
    }
  }

  nav.tab {
    background-color: #f7f9fa;
    border-bottom: 1px solid #e8e8e8;
    ul {
      margin-bottom: 0;
    }
    li {
      transition: all 0.3s cubic-bezier(0.19, 1, 0.22, 1);
      display: inline-block;
      padding: 1.3em 1.8em;
      font-size: 16px;
      cursor: pointer;
      border-bottom: 3px solid transparent;
      box-shadow: 0 0 0 1px #eeeeee;
      margin-left: -3px;
    }
  }
  
  > main {
    margin-bottom: 5em;
    div.content {
      display: block;
    }
    .primary {
      font-weight: bold;
    }
  }
  section.procedure {
    picture {
      position: relative;
      span {
        position: absolute;
        left: 0.7em;
        font-size: 1.8em;
        top: 0.25em;
        color: #fff;
      }
    }
    div.holder {
      display: flex;
    }
    div.stand {
      position: absolute;
      left: -6.1em;
      top: -1em;
    }
    aside.verticleDots {
      width: 10em;
      border-left: 4px dotted #cce6ff;
      height: 53em;
    }
  }

  section.requirements {
    table {
      border-collapse: collapse;
      background-color: #f2f2f2;
      tr {
        border: 1px solid white; 
        padding: 1em;
      }
      td {
        border: 1px solid white;
        padding: 1em;
      }
      thead, .head {
        text-align: center;
        font-size: 18px;
        vertical-align: middle;
        padding: 1em;
      }
      .head {
        width: 10em;
      }
    }
    p {
      font-size: 18px;
      line-height: 2em;
    }

    .others {
      margin: 2em auto;
    }
  }

  section.policies {
    header {
      font-size: 18px;
      p {
        line-height: 1.5em;
      }
    }
    main {
      p {
        margin: 0px auto;
      }
    }
    ul.policy > li {
      margin: 2em auto;
    }
  }

}

// 帮助中心
section.assistance {
  margin: $appMargin;
  h1 {
    text-align: center;
    font-size: 24px;
    font-weight: 400;
  }
  h2 {
    font-size: 20px;
    font-weight: 400;
    padding: 0px;
  }
  h3 {
    font-size: 16px;
    font-weight: 400;
    padding: 0px;
  }
  div.content {
    display: block;
    max-width: 500px;
    margin:0px auto;
  }
  section.faq {
    text-align: center;
    padding: 4em 0px 6em 0px;
    h3.tel {
      font-size: 20px;
      margin: 0.5em auto;
    }
    p.contact {
      font-size: 18px;
      margin: 2em auto;
    }
    p.tel {
      margin: 1em auto;
      font-size: 30px;
    }
  }

  section.download {
    padding: 4em 0px;
    background-color: #f0f3f7;
    picture {
      display: inline-block;
    }
    div.content {
      padding-left: 2em;
    }
    h1 {
      margin-bottom: 2em;
    }
    li {
      margin: 1em auto;
      span {
        margin-left: 1em;
        font-size: 18px;
      }

    }
  }

}

@media only screen and (min-width: 992px) {
  .flex-left {
    display: flex;
    flex-direction: row;
  }

  .flex-right {
    // usage e.g.: div align right.
    // can also replace Bootstrap col-md-push and col-md-pull back in bootstrap 3
    display: flex;
    flex-direction: row-reverse;
  }

  section.about > footer{
    .logo {
      width: 50rem;
    }
  }
}